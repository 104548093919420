/*
 * @Author: 刘晟楷
 * @Date: 2021-07-23 17:16:31
 * @LastEditTime: 2021-07-29 15:29:20
 * @LastEditors: 刘晟楷
 * @Description:
 * @FilePath: \frontend_vue\mockServer\router\work\getList.js
 * @CopyRight: zhonghu
 */
const Mock = require("mockjs");
//请求方式: get post put del all
const type = "get";
//接口是否完成
const complete = false;

const handler = ctx => {
    ctx.body = Mock.mock({
        code: 40000,
        message: "",
        data: {
            current: 0,
            "list|10": [
                {
                    number: "@id()",
                    "operateStatus|1-3": ["@pick(1, 2, 3, 4, 5)"],
                    numClass: "@pick(1, 2)",
                    numStatus: "@pick(1, 2, 3, 4, 5)",
                    callinMinFee: "@integer(1,100)",
                    typeCode: "@guid()",
                    destineEndTime: "@datetime()"
                }
            ],
            pages: 0,
            size: 10,
            total: 6,
        }
    });
};

module.exports = {
    type,
    complete,
    handler
};