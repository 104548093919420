import {getNumberSection, getNumberLevel, getNumberType, getDataCube} from "@/api/number";

export default {
    namespaced: true,
    state: {
        // 操作状态
        operateStatus: [
            {text: "可查询", value: 1, type: 'success'},
            {text: "查询中", value: 2, type: 'danger'},
            {text: "可预占", value: 3, type: 'success'},
            {text: "已预占", value: 4, type: 'danger'},
            {text: "可协商", value: 5, type: 'success'},
            {text: "预占申请中", value: 9, type: 'danger'},
        ],
        // 证件类型
        personType: [
            {text: '身份证', value: '1'},
            {text: '护照', value: '2'},
            {text: '港澳台通行证', value: '3'},
            {text: '其他', value: '4'},
        ],
        // 人员规模
        companyScope: [
            {text: "50人以下", value: "1"},
            {text: "50~99人", value: "2"},
            {text: "100~499人", value: "3"},
            {text: "500人以上", value: "4"},
        ],
        // 性别
        personSex: [
            {text: '男性', value: '1'},
            {text: '女性', value: '2'},
        ],
        // 绑定电话类型
        seatNumType: [
            {text: "法人电话", value: "1"},
            {text: "企业电话", value: "2"},
            {text: "其他", value: "3"}
        ],
        // 套餐类型
        mealType: [
            {text: '标准套餐', value: '1'},
            {text: '活动套餐', value: '2'}
        ],
        // 号码质量
        levelNum: [],
        // 号码类型
        typeCode: [],
        numClass: [
            {text: "快速开通", value: 1},
            {text: "可选号", value: 2}
        ],
        // 号码状态
        numStatus: [
            {text: "可用", value: 1},
            {text: "不可用", value: 2},
            {text: "企业已使用", value: 3},
            {text: "冷冻期", value: 4},
            {text: "运营商拆机", value: 5}
        ],
        // 提单状态
        orderStatus: [
            {text: "已提单", value: 1},
            {text: "未提单", value: 2},
        ],
        // 实名审核状态
        materialAuditStatus: [
            {text: '终审失败', value: '-2'},
            {text: '初审失败', value: '-1'},
            {text: '初审通过', value: '1'},
            {text: '终审通过', value: '2'},
            {text: '待审核', value: '3',},
            {text: '只保存不提交', value: '4'},
            {text: '待提交', value: '5'},
        ],
        sections: [],
        dataCube: null,
    },
    mutations: {
        setSections(state, data = null) {
            state.sections = data;
        },
        setLevels(state, data = null) {
            state.levelNum = data;
        },
        setTypeCode(state, data = null) {
            state.typeCode = data;
        },
        setDataCube(state, data = null) {
            state.dataCube = data;
        },
    },
    actions: {
        // 获取号段信息
        getSections({state, commit}) {
            if (state.sections.length === 0) {
                getNumberSection().then(res => {
                    let data = res.data;
                    // 构造数据格式
                    commit('setSections', Object.keys(data).map(value => ({text: data[value], value})));
                });
            }
        },
        // 获取号码质量
        getLevels({state, commit}, levelStatus = 1) {
            if (state.levelNum.length === 0) {
                getNumberLevel({levelStatus}).then(res => {
                    let data = res.data;
                    // 构造数据格式
                    commit('setLevels', Object.keys(data).map(value => ({text: data[value], value})));
                });
            }
        },
        // 获取号码类型
        getTypeCodes({commit}, sectionCode = '') {
            if (sectionCode) {
                getNumberType({sectionCode}).then(res => {
                    let data = res.data;
                    commit('setTypeCode', Object.keys(data).map(value => ({text: data[value], value})));
                });
            }
        },
        // 配额
        getDataCube({commit}) {
            getDataCube().then(res => {
                commit('setDataCube', res.data);
            });
        }
    }
};
