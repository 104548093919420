/**
 * 配置文件
 *
 * 如若配置开发环境请,
 * 请基于config.local.js.bak文件创建config.local.js
 * 并修改其中的内容.
 * 而非修改本文件.
 */
let config = {
    //接口地址
    api: {
        //development: "https://aurochs-api.linkallchina.com/",
        development: "http://jyagent.frp.linkallchina.net:88/v1/",
        test: "https://aurochs-api.linkallchina.net/",
        production: "https://a2-api.linkallchina.com/v1/"
    },
    wxid: {
        ///development: "wx4c834a2bdb56d6c0",
        development: "wx4c834a2bdb56d6c0",
        test: "wx4c834a2bdb56d6c0",
        production: "wxfbb006a8f8c6a83b"
    },
    redirectUrl: {
        development: "http://jy.frp.linkallchina.net/#/error/",
        test: "https://wechat.linkallchina.net/#/error/",
        production: "https://a2-wx.linkallchina.com/#/error/"
    },
    mockConfig: {
        //服务端口
        port: 3999,
        //服务地址
        // url: "http://127.0.0.1",
        url: "http://192.168.102.46",
        //允许跨域的地址
        allowOrigins: [
            "http://localhost:8080",
            "http://localhost:8081",
            "http://127.0.0.1:8080",
            "http://192.168.102.46:8080",
            "http://wangqiang.frp.linkallchina.net",
            "http://jy.frp.linkallchina.net",
        ]
    },
};

if (process.env.NODE_ENV === "development") {
    try {
        const localConfig = require("./config.local.js");
        config = Object.assign(config, localConfig.default);
    } catch (err) {
        // console.log("catch", err);
    }
}

exports.api = config.api[process.env.NODE_ENV];
exports.wxid = config.wxid[process.env.NODE_ENV];
exports.redirectUrl = config.redirectUrl[process.env.NODE_ENV];
exports.mockConfig = config.mockConfig;
