// 返回当前时间戳
const getTime = () => new Date().getTime();

class Storage {
    constructor(type = 'local') {
        this.type = type === 'session' ? sessionStorage : localStorage;
    }

    // 设置缓存
    set(params) {
        let options = {
            name: '',
            value: '',
            expires: '', // 有效期，单位秒
            startTime: getTime(), // 记录何时将值存入缓存，毫秒级
            ...params
        }
        if (options.expires) {
            // 如果options.expires设置了的话
            // 以options.name为key，options为值放进去
            this.type.setItem(options.name, JSON.stringify(options));
        } else {
            // 如果options.expires没有设置，就判断一下value的类型
            let type = Object.prototype.toString.call(options.value);
            // 如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
            if (type === '[object Object]' || type === '[object Array]') {
                options.value = JSON.stringify(options.value);
            }
            this.type.setItem(options.name, options.value);
        }
    }

    // 拿到缓存
    get(name) {
        let res = this.type.getItem(name);
        if (!res) return null;
        // 先将拿到的试着进行json转为对象的形式
        let item;
        try {
            item = JSON.parse(res);
        } catch (error) {
            // 如果不行就不是json的字符串，就直接返回
            item = res;
        }
        // 如果有expires的值，说明设置了失效时间
        if (item.expires) {
            let date = getTime();
            // 何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
            let expires = item.expires * 1000; // 转毫秒
            if (date - item.startTime > expires) {
                // 缓存过期，清除缓存，返回false
                this.remove(name);
                return null;
            } else {
                // 缓存未过期，返回值
                return item.value;
            }
        } else {
            // 如果没有设置失效时间，直接返回值
            return item;
        }
    }

    // 移出缓存
    remove(name) {
        this.type.removeItem(name);
    }

    // 追加
    push(name, value) {
        // 如果已经存在了，追加一条
        let item = this.get(name);
        let params = {name};
        if (item) {
            // 判断类型
            if (!Array.isArray(item)) return false;
            item.push(value);
            params.value = item;
        } else {
            // 如果没有就重新存一条
            params.value = [value];
        }
        this.set(params);
    }

    // 移出全部缓存
    clear() {
        this.type.clear();
    }
}

export default Storage;
