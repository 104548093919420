// code: 40000
// data: {token: "2617c259-0eeb-440b-af6e-cbc9171d3fc2", openid: null,…}
// openid: null
// refLocalToken: "4bf10973-4d62-47e6-b4d8-10411eae6ac9"
// token: "2617c259-0eeb-440b-af6e-cbc9171d3fc2"
// tokenExpiredTime: 1128
// msg: null

const Mock = require("mockjs"); //https://github.com/nuysoft/Mock/wiki/Getting-Started

//请求方式: get post put del all
const type = "post";
//接口是否完成
const complete = false;

//处理方法
//ctx https://koa.bootcss.com/#context
//router https://github.com/ZijianHe
const handler = ctx => {
    return new Promise(resolve => {
        setTimeout(() => {
            ctx.body = Mock.mock({
                code: 40000,
                msg: "",
                data: {
                    tokenExpiredTime: 86400,
                    openid: "@id()",
                    token: "",
                    refLocalToken: "@id",
                }
            });
            resolve();
        }, 1000);
    });
};

module.exports = {
    type,
    complete,
    handler
};
