/**
 * 限制文本框输入(仅数字)
 * 可通过参数限制最大|最小值|保留几位小数
 * <el-input v-int="{min: 0, max: 10, precision: 2}">
 */
const int = {};

int.install = (Vue) => {
    Vue.directive('int', {
        bind(el, binding, vNode) {
            // binding.value 有指令的参数
            let content;
            // 设置输入框的值,触发input事件,改变v-model绑定的值
            const doInput = val => {
                if (vNode.componentInstance) {
                    // 如果是自定义组件就触发自定义组件的input事件
                    vNode.componentInstance.$emit('input', val);
                } else {
                    // 如果是原生组件就触发原生组件的input事件
                    el.value = val;
                    el.dispatchEvent(new Event('input'));
                }
            }
            const input = el.getElementsByTagName("input")[0];
            let reg = /[^\d.]/g;
            if (reg.test(input.value)) {
                input.value = input.value.replace(reg, "");
                doInput(input.value);
            }
            // 失去焦点=>保留指定位小数
            el.addEventListener('focusout', event => { // 此处会在 el-input 的 @change 后执行
                const e = event || window.event;
                content = parseFloat(e.target.value);
                // if (!content) content = 0.00;
                if (typeof content !== 'undefined') {
                    let {min, max, precision} = binding.value || {};
                    if (max !== undefined && content > max) {
                        doInput(max);
                        content = max;
                    }
                    if (min !== undefined && content < min) {
                        doInput(min);
                        content = min;
                    }
                    let argPrecision = 0;// 默认保留至整数
                    if (content && typeof precision !== 'undefined') {
                        argPrecision = parseFloat(precision);
                        e.target.value = content.toFixed(argPrecision);
                        doInput(e.target.value);
                    }
                }
            });
        }
    });
}
export default int;
