import {Session} from "@/utils/storage";
import {accountLogin, getTreeGroup} from "@/api/user";
import {USER_TOKEN, REF_TOKEN, ACCOUNT_INFO, LOGIN_STATE} from "@/utils/keys";

export default {
    namespaced: true,
    state: {
        token: Session.get(USER_TOKEN), //登录token
        openid: null,
        accountInfo: Session.get(ACCOUNT_INFO),
        loginState: Session.get(LOGIN_STATE),
        deptInfo: null, // 部门|员工
    },
    mutations: {
        // 设置token
        setToken(state, token = null) {
            if (token) {
                Session.set({name: USER_TOKEN, value: token});
            } else {
                Session.remove(USER_TOKEN);
            }
            state.token = token;
        },
        // 设置token
        setRefLocalToken(state, token = null) {
            if (token) {
                Session.set({name: REF_TOKEN, value: token});
            } else {
                Session.remove(REF_TOKEN);
            }
            state.token = token;
        },
        // 设置openid
        setOpenid(state, openid = null) {
            state.openid = openid;
        },
        // 存储登录信息
        setAccountInfo(state, data = null) {
            if (data) {
                Session.set({name: ACCOUNT_INFO, value: data});
            } else {
                Session.remove(ACCOUNT_INFO);
            }
            state.accountInfo = data;
        },
        setLoginState(state, value = null) {
            if (value) {
                Session.set({name: LOGIN_STATE, value: value});
            } else {
                Session.remove(LOGIN_STATE);
            }
            state.loginState = value;
        },
        setDeptInfo(state, data = {}) {
            state.deptInfo = data;
        },
    },
    actions: {
        // 获取部门和员工列表
        getDeptInfo({commit, state}) {
            let {userCode = ''} = state.accountInfo || {};
            return new Promise((resolve, reject) => {
                if (userCode) {
                    getTreeGroup({userCode, isLoadStaff: true}).then(res => {
                        let {data} = res;
                        let result = {groupList: [data], staffList: getDeptStaffs(data)};
                        commit('setDeptInfo', result);
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    });
                } else {
                    reject();
                }
            });
        },
        // 登录
        toLogin({commit}, data) {
            return new Promise((resolve, reject) => {
                accountLogin(data).then(res => {
                    let {token, refLocalToken} = res.data;
                    commit('setToken', token);
                    commit('setRefLocalToken', refLocalToken);
                    resolve(res.data);
                }).catch(reject);
            });
        },
        // 退出登录
        logout({commit}) {
            commit('setToken');
            commit('setRefLocalToken');
            commit('setAccountInfo');
        },
    }
};
