// 用户登录唯一标识
export const USER_TOKEN = 'USER_TOKEN';
// token失效，刷新标识
export const REF_TOKEN = 'REF_TOKEN';
// 用户信息
export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const LOGIN_STATE = 'LOGIN_ERROR';
// 手机号正则
export const MOBILE_REG = /^1[3-9][0-9]{9}$/;
// 座机正则
export const TEL_REG = /^(010|0[2-9][0-9])[0-9]{8,9}$/;
// 身份证
export const IDCARD_REG = /^(^\d{15}$)|(^\d{17}([0-9]|X)$)$/;
// 邮箱正则
export const EMAIL_REG = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
// 微信正则
export const WECHAT_REG = /^[a-zA-Z-][a-zA-Z0-9_-]{5,19}$/;
// QQ号正则
export const QQ_REG = /^[1-9][0-9]{4,9}$/;
