import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/iconfonts/iconfont.css';
import '@/styles/index.scss';
import '@/filters';
import '@/directives';
import Vant from 'vant';
import 'vant/lib/index.less';
import Message from "@/components/MyPlugins/message";
import 'remixicon/fonts/remixicon.css';
import '@/utils/$getArea';
import '@/utils/$file';
import '@/utils/$idcard';
import eruda from "eruda";
// 移动端调试工具
if (process.env.NODE_ENV !== "production") {
    eruda.init();
}

Vue.use(Vant);

Vue.use(Message.install);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
