import {Session} from "@/utils/storage";
import {REF_TOKEN, USER_TOKEN} from "@/utils/keys";

/**
 * 获取用户token
 * @return {string}
 */
export function getToken() {
    return Session.get(USER_TOKEN);
}

/**
 * 获取用户刷新token
 * @return {string}
 */
export function getRefToken() {
    return Session.get(REF_TOKEN);
}

/**
 * 设置用户token
 * @param token
 */
export function setToken(token) {
    let params = {name: USER_TOKEN, value: token};
    return token ? Session.set(params) : Session.remove(USER_TOKEN);
}

/**
 * 时间戳转为日期格式
 * @param timestamp
 * @param fmt
 * @returns {string}
 */
export function timeFormat(timestamp, fmt = "yy-MM-dd hh:mm:ss") {
    if (!timestamp) return "";
    if (timestamp.length === 10) {
        timestamp = timestamp * 1000;
    }
    let date = new Date(Number(timestamp));
    let weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "a+": date.getHours() > 12 ? "下午" : "上午", // 上午/下午
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "w+": weeks[date.getDay()] // 周
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, date.getFullYear() + "");
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );

    return fmt;
}

/**
 * 十六进制颜色码转rgba
 * @param hex
 * @param alpha
 * @return {string}
 */
export function hexToRgb(hex, alpha = 0.3) {
    if (!hex) return '';
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    let color = hex.toLowerCase();
    if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
            let colorNew = "#";
            for (let i = 1; i < 4; i += 1) {
                colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
            }
            color = colorNew;
        }
        // 处理六位的颜色值，转为RGB
        let colorChange = [];
        for (let i = 1; i < 7; i += 2) {
            colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
        }
        return `rgba(${colorChange.join(",")}, ${alpha})`;
    } else {
        return color;
    }
}

/**
 * 递归获取所有部门下的员工
 * @param item
 * @param children
 * @param key
 * @param result
 * @returns {*[]}
 */
export function getDeptStaffs(item, children = 'subDeps', key = 'subStaffs', result = []) {
    let {[key]: subStaffs, [children]: subDeps = []} = item;
    if (subStaffs && subStaffs.length > 0) subStaffs.map(item => result.push(item));
    if (subDeps.length > 0) {
        subDeps.forEach(dep => getDeptStaffs(dep, children, key, result));
    }
    return result;
}

/**
 * 递归获取本部门信息
 * @param data
 * @param groupCode
 * @param key
 * @returns {string|*}
 */
export function getSelfDept(data, groupCode = '', key = '') {
    if (data.length === 0) return '';
    for (let i in data) {
        let curr = data[i];
        if (curr.groupCode === groupCode) return key ? curr[key] : curr;
        if (curr.subDeps) {
            let result = getSelfDept(curr.subDeps, groupCode, key);
            if (result) return result;
        }
    }
}

/**
 * 生成uuid
 * @returns {string}
 * @constructor
 */
export function UUID() {
    let str = "0123456789abcdef";
    let arr = [];
    for (let i = 0; i < 36; i++) {
        arr.push(str.substr(Math.floor(Math.random() * 0x10), 1));
    }
    arr[14] = 4;
    arr[19] = str.substr((arr[19] & 0x3) | 0x8, 1);
    arr[8] = arr[13] = arr[18] = arr[23] = "-";
    return arr.join("");
}

/**
 * 防抖
 * @param func
 * @param wait
 * @return {function(): void}
 */
export function debounce(func, wait) {
    let timer;
    return () => {
        let context = this; // 注意 this 指向
        let args = arguments; // arguments中存着e

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            func.apply(context, args)
        }, wait)
    }
}

/**
 * 获取随机密码
 * @param n 长度
 * @returns {string}
 */
export function getRandomPassword(n) {
    //以下为排除易混淆字符后的库
    // let special = '!@#$%&*+'.split('');
    const number = "3456789".split("");
    const letter_low = "abcdefghjgkmnpqrstuvwxy".split("");
    const letter_cap = "ABCDEFGHJKLMNPQRSTUVWXY".split("");
    const rs = []; //结果集
    n = n || 8;
    //根据范围返回随机数，最小，最大
    const random = function (min, max) {
        let diff = max - min;
        return Math.round(Math.random() * diff + min);
    };
    //从库里取字符，数量，库
    const getLetter = function (len, type) {
        while (len > 0) {
            rs.push(type[random(0, type.length - 1)]);
            len--;
        }
    };

    //取一个数字
    getLetter(1, number);
    //取一个小写字母
    getLetter(1, letter_low);
    //取一个大写字母
    getLetter(1, letter_low);
    //剩下的随机取数字或字母
    getLetter(n - 3, number.concat(letter_low, letter_cap));

    //打乱生成的密码
    rs.sort(function () {
        return Math.random() > 0.5 ? -1 : 1;
    });
    return rs.join("");
}

/**
 * 多数据类型判空
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value) {
    if (!value) return true;
    let val = JSON.parse(JSON.stringify(value));
    if (typeof val === 'boolean') return false;
    // if (typeof val === 'number') return false;
    // 数组
    if (val instanceof Array) {
        let arr = val.filter(Boolean);
        if (arr.length === 0) return true;
    } else if (val instanceof Object) { // 对象
        if (JSON.stringify(val) === '{}') return true;
    } else {
        return ['null', null, 'undefined', undefined, '0', 0].includes(val) || val === '';
    }
    return false;
}

/**
 * 正则验证
 * @param regs
 * @returns {(function(*): (boolean|boolean))|*}
 */
export function regex(regs = []) {
    return (value) => {
        if (!value) return true;
        return regs.some(reg => reg.test(value));
    }
}

/**
 * 唯一id
 * @returns {string}
 */
export function uuid() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

/**
 * 字节转换
 * @param bytes
 * @param min 指定最小单位
 * @return {string}
 */
export function bytesToSize(bytes) {
    if (isNaN(bytes)) return '';
    let symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let exp = Math.floor(Math.log(bytes) / Math.log(2));
    if (exp < 1) exp = 0;
    let i = Math.floor(exp / 10);
    bytes = bytes / Math.pow(2, 10 * i);

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2);
    }
    return bytes + ' ' + symbols[i];
}

/**
 * 获取图片的Blob值
 * @param url
 * @param cb
 */
export function getFileBlob(url, cb) {
    let xhr = new XMLHttpRequest();
    xhr.open("get", url, true);
    xhr.responseType = "blob";
    xhr.onload = function() {
        if (this.status === 200) {
            if(cb) cb(this.response);
        }
    };
    xhr.onerror = e => {
        console.log('error', e);
    }
    xhr.send();
}

let recursionArea = (data, value) => {
    let trees = [],
        index = 0;
    let hasParentId = function loop(data, index) {
        return data.some((item) => {
            if (item.value === value) {
                trees = trees.slice(0, index)
                trees[index] = item.label;
                return true;
            } else if (Array.isArray(item.children)) {
                trees[index] = item.label;
                return loop(item.children, index + 1);
            } else {
                return false;
            }
        });
    }(data, index);

    return hasParentId ? trees : []
}

/**
 * 递归获取省市区文字
 * @return {string}
 * @param value
 * @param areas
 */
export function getAreas(value = 0, areas) {
    if (!value) return '';
    let result = recursionArea(areas, value);
    return result.join(' / ');
}
