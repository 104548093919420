import Vue from 'vue';
import VueRouter from 'vue-router';
import {getToken, UUID} from "@/utils/tools";
import {wxid, redirectUrl} from '@/config';
import {useAutoLogin} from "@/api/user";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        component: () => import('@/views/login')
    },
    {
        path: '/',
        name: 'Index',
        redirect: {name: 'NumberList'}, // 默认重定向到急速选号
    },
    {
        path: '/number-list',
        name: 'NumberList',
        meta: {
            title: '急速选号'
        },
        component: () => import('@/views/number-list'),
        children: [
            {
                path: 'detail',
                name: 'NumberDetail',
                meta: {
                    title: '号码详情'
                },
                component: () => import('@/views/number-list/detail')
            },
            {
                path: 'detail1',
                name: 'NumberDetail1',
                meta: {
                    title: '号码详情'
                },
                component: () => import('@/views/number-list/detail1')
            }
        ]
    },
    {
        path: '/open-order',
        name: 'OpenOrder',
        meta: {
            title: '开通'
        },
        component: () => import('@/views/open-order'),
    },
    {
        path: '/materials',
        name: 'Materials',
        meta: {
            title: '上传实名材料'
        },
        component: () => import('@/views/materials'),
        children: [
            {
                path: 'detail',
                name: 'MaterialsDetail',
                meta: {
                    title: '实名材料上传'
                },
                component: () => import('@/views/materials/detail')
            }
        ]
    },
    {
        path: '/audit-log',
        name: 'AuditLog',
        meta: {
            title: '审核记录'
        },
        component: () => import('@/views/audit-log'),
    },
    {
        path: '/draft-list',
        name: 'DraftList',
        meta: {
            title: '草稿箱'
        },
        component: () => import('@/views/draft-list'),
    },
    {
        path: '/error',
        name: 'Error',
        meta: {
            title: '登录失败'
        },
        component: () => import('@/views/error')
    },
];

const router = new VueRouter({routes});

// 免登录白名单
export const whiteList = ['/error', '/login'];

router.beforeEach((to, from, next) => {
    const token = getToken();
    if (!token) {
        let {hash, origin, search, href} = window.location;
        let {query} = to;
        let loginState = store.state.user.loginState;
        if (loginState && to.path === '/error' && !query.code) {
            next();
        } else if (href.indexOf('code') !== -1) {
            // vue路由hash模式，在回调之后url会改变（取不到参数）
            if (!query.code) {
                // 需要重新拼接为hash形式，再跳转一下
                let hashUrl = `${origin}/${hash}&${search.slice(1)}`;
                // console.log('hashUrl', hashUrl);
                window.location.replace(hashUrl);
            } else {
                // 尝试自动登录
                useAutoLogin({code: query.code, wxid}).then((res) => {
                    let {data} = res;
                    // 登录成功并且已经绑定过了，跳转首页
                    if (data.token) {
                        let {redirect} = to.query;
                        // setToken(data.token);
                        store.commit('user/setToken', data.token);
                        store.commit('user/setRefLocalToken', data.refLocalToken);
                        store.commit("user/setAccountInfo",data.user);
                        next({path: redirect || '/', replace: true});

                    } else if (data.openid) { // 未绑定，跳转到登录页
                        // 把openid存一下，登录时带着
                        store.commit('user/setOpenid', data.openid);
                        // token, openid
                        if (!whiteList.includes(to.path)) {
                            next({name: 'Error', replace: true});
                        } else {
                            next();
                        }
                    } else {
                        store.commit('user/setLoginState', 1);
                        next({name: 'Error', replace: true});
                    }
                }).catch(err => {
                    if (err.code === 40111) {
                        store.commit('user/setLoginState', 1);
                        next({name: 'Error', replace: true});
                    } else {
                        window.location.replace(origin);
                    }
                });
            }
        } else {
            // 重置登录状态
            store.commit('user/setLoginState');
            const appid = wxid;
            // 对当前url转义
            let href = `${redirectUrl}?redirect=${to.fullPath}`;
            // let href = `http://wangqiang.frp.linkallchina.net/#/error?redirect=${to.path}`;
            const redirectUri = encodeURIComponent(href);
            // 唯一id
            const uuid = UUID();
            // 构建url
            const URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${uuid}#wechat_redirect`;
            window.open(URL, '_self');
        }
    } else {
        if (whiteList.includes(to.path)) {
            next({path: '/', replace: true});
        } else {
            next();
        }
    }
});

router.afterEach((to) => {
    //放在afterEach会更准确，有些重定向、跳转导致页面name不准确。
    //比如登录成功后，在进入登录页，虽然页面会定位到dashboard，但title是登录。
    document.title = to.meta.title || '';
});

export default router;
