const Mock = require("mockjs"); //https://github.com/nuysoft/Mock/wiki/Getting-Started

//请求方式: get post put del all
const type = "post";
//接口是否完成
const complete = false;

//处理方法
//ctx https://koa.bootcss.com/#context
//router https://github.com/ZijianHe
const handler = ctx => {
    return new Promise(resolve => {
        setTimeout(() => {
            ctx.body = Mock.mock({
                code: 40000,
                message: "",
                data: {
                    expiresIn: 86400,
                    nickname: "@cname()",
                    token: "@id",
                    isAuthorize: "@pick(false)",
                }
            });
            resolve();
        }, 1000);
    });
};

module.exports = {
    type,
    complete,
    handler
};
