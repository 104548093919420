import request from "@/utils/request";

// 选择企业列表
export const chooseUserList = (params) => request({url: "wechat/user-list", method: 'get', params});
// 添加企业
export const addCompany = (data) => request({url: "wechat/create-user", method: 'post', data});
// 模糊查询企业信息
export const getApplyInfoFuzzy = (params) => request({
    url: "wechat/enterprise-search",
    method: 'get',
    params
});
// 查询企业详细信息
export const getApplyInfo = (params) => request({url: "wechat/enterprise-mask-info", method: 'get', params});
// 证件识别
export const certificateOcr = (data) => request({url: "wechat/ocr-upload", method: 'post', data});
// 地区信息
export const addressOpts = (params) => request({url: "wechat/address-tree", method: 'get', params});
// 行业门类
export const industryOpts = (params) => request({url: "wechat/mince-tree", method: 'get', params});
// 三要素验证
export const numOpenValidatePhone = (data) => request({
    url: "wechat/num-open-validate-phone",
    method: 'post',
    data
});
// 结算方式
export const getDeductTypeList = (params) => request({url: "wechat/deduct-type-list", method: 'get', params});
// 获取套餐
export const getMealList = (params) => request({url: "wechat/query-meal", method: 'get', params});
// 获取阿里云oss签名信息
export const getAliOssSignature = (params) => request({url: "wechat/get-oss-signature", method: 'get', params});
// 保存草稿
export const draftSave = (data) => request({url: "wechat/draft-save", method: 'post', data});
// 提交订单
export const submitOrder = (data) => request({url: "wechat/submit-order", method: 'post', data});
// 审核记录
export const numHoldMaterialListNew = (params) => request({
    url: "wechat/material-audit-list",
    method: 'get',
    params
});
// 草稿箱
export const draftList = (params) => request({
    url: "wechat/draft-list",
    method: 'get',
    params
});
// 删除草稿
export const delDraft = (params) => request({
    url: "wechat/draft-del",
    method: 'get',
    params
});
// 获取草稿内容
export const draftDetail = (params) => request({
    url: "wechat/draft-detail",
    method: 'get',
    params
});
// 开通记录
export const numHoldApply = (params) => request({
    url: "wechat/open-list",
    method: 'get',
    params
});
// 获取实名材料上传列表
export const getNumOpenDoc = (params) => request({
    url: "wechat/get-num-open-doc",
    method: 'get',
    params
});
// 提交实名材料
export const submitOrderFile = (data) => request({
    url: "wechat/submit-order-file",
    method: 'post',
    data
});
// 获取代理商成本
export const getAgentCost = (params) => request({
    url: "wechat/user-cost",
    method: 'get',
    params
});
// 查询企业详情
export const chooseCompany = (params) => request({url: "wechat/choose-user-info", method: 'get', params});
// 获取增值功能基础表
export const getBasicProductList = (params) => request({url: "wechat/basic-products", method: 'get', params});
