const defaults = {
    title: null,
    message: '',
    type: '',
    inputValue: null,
    inputType: 'text',
    confirmButtonLoading: false,
    cancelButtonLoading: false,
    confirmButtonText: '',
    cancelButtonText: '',
    confirmButtonType: 'info',
    cancelButtonType: 'default',
    placement: undefined
};

import main from "./main";
import Vue from 'vue';
//创建类
const MessageConstructor = Vue.extend(main);

let instance;

/**
 * 默认回调操作
 * @param action
 */
const defaultCallback = action => {
    window.console.log('action', action);
    if (action === 'confirm') {
        if (instance.showInput) {
            instance.resolve({ value: instance.inputValue, action });
        } else {
            instance.resolve(action);
        }
    } else if (instance.reject && (action === 'cancel' || action === 'close')) {
        instance.reject(action);
    }
};

/**
 * 初始化实例
 */
const initInstance = () => {
    // 创建实例
    instance = new MessageConstructor({
        el: document.createElement('div')
    });

    instance.callback = defaultCallback;
}

/**
 * 默认消息
 * @param options
 * @returns {Promise<unknown>}
 * @constructor
 */
const Message = (options) => {
    if (!instance) initInstance();

    let opts = {...defaults, ...options};

    for (let prop in opts) {
        let hasOwn = Object.prototype.hasOwnProperty.call(opts, prop);
        if (hasOwn) {
            instance[prop] = opts[prop];
        }
    }

    return new Promise((resolve, reject) => {
        instance.resolve = resolve;
        instance.reject = reject;

        document.body.appendChild(instance.$el)
        Vue.nextTick(() => instance.visible = true);
    });
};

/**
 * 确认框
 * @param message
 * @param title
 * @param options
 * @returns {Promise<*>}
 */
Message.confirm = (message, title, options) => {
    return Message({
        message,
        title,
        showInput: false,
        type: 'confirm',
        ...options
    });
}

/**
 * 提交内容
 * @param message
 * @param title
 * @param options
 * @returns {Promise<*>}
 */
Message.prompt = (message, title, options) => {
    return Message({
        message,
        title,
        showInput: true,
        type: 'prompt',
        ...options
    });
}

/**
 * 绑定到vue原型
 */
Message.install = () => {
    Vue.prototype.$message = Message;
    Vue.prototype.$confirm = Message.confirm;
    Vue.prototype.$prompt = Message.prompt;
}

export default Message;
