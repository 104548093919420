export default {
    namespaced: true,
    state: {
        row: null
    },
    mutations: {
        updateRow(state, row = null) {
            state.row = row;
            setTimeout(() => state.row = null, 0);
        }
    },
    actions: {

    }
}