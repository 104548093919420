const Router = require("koa-router");
const router = new Router({});

const context = require("require-context");
const files = context(__dirname, true, /\.js$/);

files.keys().map(element => {
    let module = require(__dirname + "/" + element);
    let path = "/" + element.replace(/\\/g, "/").replace(/\.js/, "");
    path = path.replace(/\/index/g, ""); //index默认为当前目录
    if (
        element === "index.js" ||
        Object.keys(module).length === 0 ||
        !module.type ||
        typeof module.handler != "function"
    ) {
        //忽略当前文件, 忽略空文件, 忽略没有请求方法的文件
        return;
    }
    //type: get post put del all
    router[module.type](path, module.handler);
});

module.exports = router;
