import Vue from 'vue';
import Area from "vant/lib/area";

// 创建类
const Instance = Vue.extend(Area);

/**
 * 直接构造<van-area>组件实例，通过内置方法翻译对应邮政编码
 * 根据邮编获取地区名称
 * @param code
 * @param areaList
 * @returns {string}
 */
export function getArea(code, areaList) {
    const main = new Instance({propsData: {value: code, areaList}});
    main.$mount();
    let {province, city} = main.getArea();

    return [province, city].join(' / ');
}

/**
 * 挂载全局方法
 * @param code
 * @returns {*}
 */
Vue.prototype.$getArea = getArea;
