const limit = {};

const rules = {
    // eslint-disable-next-line no-useless-escape
    default: "~!@#$%^&*()_+|`\\-={}\\[\\]:\";\'<>/ ",
    // eslint-disable-next-line no-useless-escape
    email: "~!#$%^&*()_+|`\\-={}\\[\\]:\";\'<>/ ",
};

limit.install = Vue => {
    // 限制文本框特殊字符输入
    Vue.directive('limit', {
        update(el, binding, vNode) {
            const doInput = val => {
                if (vNode.componentInstance) {
                    // 如果是自定义组件就触发自定义组件的input事件
                    vNode.componentInstance.$emit('input', val);
                } else {
                    // 如果是原生组件就触发原生组件的input事件
                    el.dispatchEvent(new Event('input'));
                }
            }
            let elNode = el.querySelector("input") || el.querySelector("textarea");
            let content = elNode.value;
            let arg = binding.arg;
            let pattern = '';
            // 自定义规则
            if (binding.value) {
                pattern = binding.value;
            } else {
                // 配置规则
                let rule = rules[arg || 'default'];
                pattern = new RegExp(`[${rule}]`);
            }
            if (pattern.test(content)) {
                elNode.value = content.replace(pattern, '');
                doInput(elNode.value);
            }
        },
    });
}

export default limit;
