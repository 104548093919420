import Vue from "vue";
import {getFileBlob} from "@/utils/tools";
import mime from "mime";

/**
 * 根据url创建file对象
 * @param url
 * @returns {Promise<unknown>}
 */
const getFile = url => {
    let mimeType = mime.getType(url);
    let arr = url.split('/');
    let filename = arr[arr.length - 1];
    return new Promise((resolve, reject) => {
        getFileBlob(url, res => {
            if (res) {
                let file = new File([res], filename, {type: mimeType});
                resolve(file);
            } else {
                reject();
            }
        });
    });
}

Vue.prototype.$file = getFile;
