const Mock = require("mockjs");

//1.请求方式
const type = "get";

//2.接口是否完成, false则走mock; true则走真实接口.
const complete = false;

//data可以是常规json数据;
// const data = {
//     code: 200,
//     msg: "",
//     data: { a: 1, b: 2 }
// };
//也可以是mock数据
const data = Mock.mock({
    code: 200,
    msg: "",
    data: {
        now: new Date(),
        "random|1-100": 0
    }
});

//处理方法
const handler = ctx => {
    //可返回http状态码
    // ctx.status = 500;
    //可直接返回mock数据
    // ctx.body = data;
    //也可延时返回
    return new Promise(resolve => {
        setTimeout(() => {
            ctx.body = data;
            resolve();
        }, 1000);
    });
};

module.exports = {
    type,
    complete,
    handler
};
