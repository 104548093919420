import request from "@/utils/request";

// 号码池
export const getNumberList = (params) => request({url: "wechat/number-list", method: 'get', params});
// 号段
export const getNumberSection = (params) => request({url: "wechat/get-sections", method: 'get', params});
// 号码质量
export const getNumberLevel = (params) => request({url: "wechat/get-levels", method: 'get', params});
// 获取号码详情
export const getNumberInfo = (params) => request({url: "wechat/number-info", method: 'get', params});
// 获取当前合作伙伴配额以及使用信息
export const getDataCube = (params) => request({url: "wechat/data-cube", method: 'get', params});
// 预查询
export const addPreCheck = (data) => request({url: "number-pool/pre-query", method: 'post', data});
// 预占
export const numberDestine = (data) => request({url: "number-pool/pre-destine", method: 'post', data});
// 取消预占申请
export const preCancel = (data) => request({url: "number-pool/pre-release", method: 'post', data});
// 预占号码
export const getNumDestined = (params) => request({url: "wechat/destine-list", method: 'get', params});
// 预占详情
export const numberDestineInfo = (params) => request({url: "wechat/destine-info", method: 'get', params});
