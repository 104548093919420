import {getMealList} from "@/api/openOrder";

export default {
    namespaced: true,
    state: {
        // 认证状态
        auditStatus: [
            {text: "已提交", value: "1"},
            {text: "未提交", value: "2"},
            {text: "只保存不提交", value: "3"},
            {text: "审核通过", value: "4"},
            {text: "审核失败", value: "5"},
            {text: "审核过期", value: "6"}
        ],
        // 实名类型
        materialType: [
            {text: '新开实名', value: '1'},
            {text: '变更套餐实名', value: '2'},
            {text: '续费实名', value: '3'},
            {text: '变更资料实名', value: '4'},
            {text: '过户', value: '5'},
            {text: '单独实名电话', value: '6'},
            {text: '重新实名', value: '7'},
            {text: '删除绑定电话', value: '8'},
        ],
        meals: []
    },
    mutations: {
        setMeals(state, list) {
            state.meals = list;
        }
    },
    actions: {
        getMeals({commit}, {batchName, mealType, deductType}) {
            if (batchName && mealType && deductType) {
                getMealList({batchName, mealType, deductType}).then(res => {
                    commit('setMeals', res.data);
                });
            }
        }
    }
}
