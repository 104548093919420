<template>
    <div>
        <van-popup v-model="visible"
                   :close-on-click-overlay="closeVisible"
                   :closeable="closeVisible"
                   :position="placement"
                   :style="{width: !placement ? '90%' : '100%'}"
                   round
        >
            <div class="MyMessage">
                <div class="MyMessage__title">{{ title }}</div>
                <div class="MyMessage__message">{{ message }}</div>
                <div class="MyMessage__input">
                    <van-field v-if="showInput"
                               v-model="inputValue"
                               :border="false"
                               :type="inputType"
                               :placeholder="inputPlaceholder"
                               rows="4"
                    />
                </div>
                <div class="MyMessage__action">
                    <van-button :disabled="!closeVisible"
                                :type="cancelButtonType"
                                block
                                style="margin-right: 16px;"
                                @click="onAction('cancel')"
                    >
                        {{ cancelButtonText || '取消' }}
                    </van-button>
                    <van-button :loading="confirmButtonLoading"
                                :type="confirmButtonType"
                                block
                                @click="onAction('confirm')"
                    >
                        {{ confirmButtonText || '确定' }}
                    </van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "MyMessage",
        data() {
            return {
                visible: false,
                showInput: false,
                title: '',
                message: '',
                action: '',
                inputValue: null,
                inputPlaceholder: '请输入留言',
                beforeClose: null,
                // 按钮loading状态
                confirmButtonLoading: false,
                cancelButtonLoading: false,
                // 文本框类型，tel、text、textarea、digit、number、password
                inputType: 'text',
                // 确认按钮默认文字
                confirmButtonText: '',
                // 取消按钮默认文字
                cancelButtonText: '',
                // 按钮类型，default、primary、info、warning、danger
                confirmButtonType: 'info',
                cancelButtonType: 'default',
                // 弹出位置
                placement: undefined
            }
        },
        computed: {
            // 是否在点击遮罩层后关闭
            closeVisible() {
                return !(this.beforeClose && this.confirmButtonLoading);
            }
        },
        methods: {
            // 动作执行方法
            onAction(action) {
                // if (this.type === 'prompt' && action === 'confirm' && !this.validate()) {
                //     return;
                // }
                this.action = action;
                if (typeof this.beforeClose === 'function') {
                    this.beforeClose(action, this, this.doClose);
                } else {
                    this.doClose();
                }
            },
            // 关闭
            doClose() {
                if (!this.visible) return;
                this.visible = false;
                window.console.log('close');
                // this._closing = true;
                //
                // this.onClose && this.onClose();
                // messageBox.closeDialog(); // 解绑
                // if (this.lockScroll) {
                //     setTimeout(this.restoreBodyStyle, 200);
                // }
                // this.opened = false;
                // this.doAfterClose();
                setTimeout(() => {
                    if (this.action) this.callback(this.action, this);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MyMessage {
        text-align: center;
        margin: 20px 16px 16px 16px;
        &__title {
            font-size: 16px;
            line-height: 24px;
        }
        &__message {
            font-size: 14px;
            margin: 20px 0;
        }
        &__input {
            margin-bottom: 16px;
            min-height: 18px;
            ::v-deep {
                .van-field {
                    padding: 0;
                    &__value {
                        background: #F5F6F8;
                        padding: 5px 10px;
                        border-radius: 4px;
                    }
                }
            }
        }
        &__action {
            display: flex;
        }
    }
</style>