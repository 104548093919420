import Vue from 'vue';
import store from "@/store";
import {getSelfDept} from "@/utils/tools";

/**
 * 根绝value找text
 * @param value
 * @param state
 * @param key
 * @returns {*}
 */
const getMapText = (value, state, key = 'text') =>  {
    let [module, name] = state.split("/");
    const map = store.state[module][name];
    if (Array.isArray(value)) {
        let res = map.filter(item => value.includes(item.value));
        return res.map(v => v[key]).join(',');
    } else {
        let {[key]: result = ''} = map.find(item => String(item.value) === String(value)) || {};
        return result;
    }
}

Vue.filter('getMapText', getMapText);

/**
 * 获取部门或员工对应名字
 * @param value
 * @returns {string|*}
 */
const getDeptName = (value) => {
    let {groupList, staffList} = store.state.user.deptInfo;
    if (value) {
        if (Array.isArray(value)) {
            let [item] = value;
            let result = [];
            // 员工
            if (item.indexOf('SID') !== -1) {
                if (staffList && staffList.length > 0) {
                    result = staffList.filter(sc => value.includes(sc.staffCode)).map(sf => sf.staffFullname);
                }
            } else if (item.indexOf('GID') !== -1) { // 部门
                if (groupList && groupList.length > 0) {
                    result = value.map(value => getSelfDept(groupList, value, 'groupName'));
                }
            }

            return result.join(',');
        } else {
            // 字符串转为数组，递归调用
            let arr = value.replace(/\s+/g, '').split(',').filter(Boolean);
            if (arr.length > 0) return getDeptName(arr);

            return '';
        }
    }
}

Vue.filter('getDeptName', getDeptName);
