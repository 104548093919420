import Vue from "vue";
const Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1];    // 加权因子
const validate = ['1', '0', '10', '9', '8', '7', '6', '5', '4', '3', '2'];            // 身份证验证位值.10代表X

/**
 * 验证18位数身份证号码中的生日是否是有效生日
 */
const isValidBrith = (number) => {
    const {y, m, d} = getBirthday(number);
    const date = new Date(y, parseFloat(m) - 1, parseFloat(d));
    // 这里用getFullYear()获取年份，避免千年虫问题
    return !(date.getFullYear() !== parseFloat(y)
        || date.getMonth() !== parseFloat(m) - 1
        || date.getDate() !== parseFloat(d));
};
/**
 * 判断身份证号码为18位时最后的验证位是否正确
 */
const isValidate = id => {
    let sum = 0;                             // 声明加权求和变量
    const idArr = id.split('');
    if (idArr[17].toLowerCase() === 'x') {
        idArr[17] = '10';                    // 将最后位为x的验证码替换为10方便后续操作
    }
    for (let i = 0; i < 17; i++) {
        sum += Wi[i] * idArr[i];            // 加权求和
    }
    const position = sum % 11;                // 得到验证码所位置

    return idArr[17] === validate[position];
};

const getAge = id => {
    let {y} = getBirthday(id);
    return new Date().getFullYear() - y;
}

const getBirthday = id => {
    let birthDay = id.substring(6, 14);
    let y = birthDay.substring(0, 4);
    let m = birthDay.substring(4, 6);
    let d = birthDay.substring(6, 8);

    return {y, m, d};
}

const getSex = id => {
    if (id.substring(14, 17) % 2 === 0) {
        return 'female';    //女
    } else {
        return 'male';  //男
    }
}

const idcard = id => {
    id = id.toString().trim();
    return new Promise(resolve => {
        if (id.length === 18 && isValidBrith(id) && isValidate(id)) {
            let {y, m, d} = getBirthday(id);
            let sex = getSex(id) === 'male' ? '1' : '2';
            let age = getAge(id);

            resolve({birthDay: `${y}-${m}-${d}`, sex, age});
        }
    });
}

Vue.prototype.$idcard = idcard;
