import Axios from 'axios';
import {api, mockConfig} from '@/config';
import {Toast, Dialog} from 'vant';
import store from "@/store";
import {getToken, getRefToken} from "@/utils/tools";

const service = Axios.create({baseURL: api, timeout: 60000});

let isRefreshing = false;
// 请求队列
let requests = [];

/**
 * 请求成功
 * @param config
 * @returns {*}
 */
const reqSuccess = config => {
    const token = getToken();
    if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
}

/**
 * 请求失败
 * @param error
 * @returns {Promise<never>}
 */
const reqError = error => Promise.reject(error);

// 响应拦截器中，有特殊的情况，需要直接返回
const verifyResponse = val => {
    let {request} = val;
    return request.responseType === "blob";
}

/**
 * 注销
 */
const logOut = () => {
    Dialog.alert({
        message: "未登录或登录超时，请重新登录",
    }).then(() => {
        store.dispatch("user/logout").then(() => location.reload());
    });
}

/**
 * 返回成功
 * @param response
 * @returns {Promise<never>|*}
 */
const resSuccess = response => {
    const data = response.data;
    // 请求结束后将对应存储的取消函数删除
    let resCode = parseInt(data.code);
    if (verifyResponse(response)) {
        return response;
    } else if (resCode !== 40000) {
        // token失效
        if (resCode === 40102) {
            let resConfig = response.config;
            if (!isRefreshing) {
                isRefreshing = true;
                const refToken = getRefToken();
                if (refToken) {
                    // 用刷新token获取新的用户token
                    return Axios.get(api + 'wechat/refresh-token', {
                        params: {refLocalToken: refToken}
                    }).then(res => {
                        let resData = res.data;
                        if (resData.code !== 40000) {
                            return logOut();
                        } else {
                            let {token} = resData.data;
                            store.commit('user/setToken', token);
                            resConfig.headers.Authorization = `Bearer ${token}`;
                            if (requests.length > 0) {
                                // 已经刷新了token，将所有队列中的请求进行重试
                                requests.forEach(cb => cb(token));
                                requests = [];
                            }
                            isRefreshing = false;
                            return service(resConfig);
                        }
                    });
                } else {
                    logOut();
                }
            } else {
                // 保存之前未请求成功的接口
                return new Promise(resolve => {
                    requests.push((token) => {
                        resConfig.headers.Authorization = `Bearer ${token}`;
                        resolve(service(resConfig));
                    });
                });
            }
        } else if (resCode === 40104) {
            return logOut();
        } else {
            Toast.fail(data.msg);
        }
        return Promise.reject(data || "Error");
    } else {
        return data;
    }
}

/**
 * 请求失败对应信息
 * @type {{"401": resErrorCode.401, "500": resErrorCode.500, "403": resErrorCode.403, "404": resErrorCode.404}}
 */
const resErrorCode = {
    401: () => {
        Dialog.alert({
            message: "未登录或登录超时，请重新登录",
        }).then(() => {
            if (!["#/login"].includes(window.location.hash)) {
                store.dispatch("user/logout").then(() => location.reload());
            }
        });
    },
    403: (msg) => {
        Dialog.alert({
            message: msg || "无权访问"
        });
    },
    404: (msg) => {
        Dialog.alert({
            message: msg || "页面未找到"
        });
    },
    500: (msg) => {
        Dialog.alert({
            message: msg || "服务器异常"
        });
    },
};

/**
 * 返回失败
 * @param error
 * @returns {Promise<never>|Promise<unknown>}
 */
const resError = error => {
    //response, request, message
    if (Axios.isCancel(error)) {
        return new Promise(() => {});
    }
    let {state, message} = error.data;
    const code = parseInt(state);
    if (resErrorCode[code]) {
        resErrorCode[code](message);
    } else {
        Toast.fail(message);
    }
    return Promise.reject(error);
}

//请求拦截器
service.interceptors.request.use(reqSuccess, reqError);
service.interceptors.response.use(resSuccess, resError);

//mock实例
const mockService = Axios.create({
    baseURL: `${mockConfig.url}:${mockConfig.port}`,
    timeout: 50000
});

//取所有mock接口的complete状态
const mockFiles = require.context("@/../mockServer/router", true, /\.js$/);
const allPath = []; //所有mock文件
const complete = mockFiles.keys().reduce((complete, modulePath) => {
    //排除根目录index文件.
    if (modulePath === "./index.js") {
        return complete;
    }
    const moduleName = modulePath.replace(/^\.(\/.*)\.\w+$/, "$1");
    const value = mockFiles(modulePath);
    allPath.push(moduleName);
    complete[moduleName] = !!value.complete; //没有的当false
    return complete;
}, {});

mockService.interceptors.request.use(reqSuccess, reqError);
mockService.interceptors.response.use(resSuccess, resError);

export default params => {
    if (process.env.NODE_ENV !== "development" || allPath.indexOf(params.url) === -1) {
        //非开发环境, 或者没有mock, 直接请求接口
        return service(params);
    } else if (complete[params.url]) {
        //开发环境, 并且接口完成, 走接口
        return service(params);
    } else {
        //未完成接口, 走mock
        return mockService(params);
    }
};
